// static WHITE = '#FFFFFF';
// static LIGHT = '#DDDDDD';
// static BLACK = '#0d0d0e';
// static DARK = '#212025';
// static GREY = '#CFCFCF';
// static DARKGREY = '#777777';
// static DARKER = '#2D2C33';
// static RED = '#ff5e57';
// static BLUE = '#0fbcf9';
// static YELLOW = '#ffdd59';
// static GREEN = '#0be881';

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import './layout.css';

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
		query SiteTitleQuery {
			site {
			siteMetadata {
				title
			}
			}
		}
		`}
		render={data => (
		<>
			<div className='layout'>
				{children}
			</div>
		</>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
